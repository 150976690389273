import {addEventListeners, handleHeaderScroll, handleHover, handleResize} from './custom/header';
import hoverintent from "./custom/hoverintent";
import {initializeGSAPAnimations} from './custom/scrollBanner';
import {initializeSlideshow, initializeSwiper} from './custom/slideshows';
import {initializeLocationMap} from './custom/locationMap';
import {stickyBox} from "./custom/stickyBox";
import {initRecaptchaForms} from "./custom/recaptchaForm";
import {buildDatepicker} from "./custom/datepicker";
import {Tooltip} from "./custom/tooltip";



function initializeHoverIntent() {
    for (let el of document.querySelectorAll('#box > div')) {
        hoverintent(el);
    }
    for (let el of document.querySelectorAll('#tire_change > div')) {
        hoverintent(el);
    }
}

function initialize() {
    handleHeaderScroll();
    document.addEventListener('DOMContentLoaded', function() {
        initializeHoverIntent();
        addEventListeners();
        handleHover();
        handleResize();
        initializeSwiper();
        initializeSlideshow();
        initializeLocationMap();
        buildDatepicker();
        initRecaptchaForms();

        let offsetTopStickyBox = 30;

        const listingTitle = localStorage.getItem('listingTitle');
        if (listingTitle) {
            const titleOnly = listingTitle.split(' (')[0];
            document.querySelector('.listingContactTitle').textContent = titleOnly;
            document.querySelector('#input_hidden').value = listingTitle;
            localStorage.removeItem('listingTitle');
        }

        var banner = document.querySelector(".jobsList .job-banner .banner-info h2");
        if(banner){
            var bannerText = banner.textContent;
            document.querySelector(".jobsList .contactformular #input_titleCollection").value = bannerText;
        }

        function applyStickyBox(element, options) {
            for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
                stickyBox(pinBox, {
                    offsetTop: offsetTopStickyBox
                });
            }
        }

        function mutationCallback(mutationsList, observer) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    for (let node of mutation.addedNodes) {
                        if (node.nodeType === 1 && node.matches('.js-stickyBox')) {
                            applyStickyBox(node, {
                                offsetTop: offsetTopStickyBox
                            });
                        }
                        for (let child of node.querySelectorAll?.('.js-stickyBox') ?? []) {
                            applyStickyBox(child, {
                                offsetTop: offsetTopStickyBox
                            });
                        }
                    }
                }
            }
        }

        const locationDropdown = document.getElementById('input_choose_location');
        const hiddenEmailInput = document.querySelector('input[name="input_recipient_email"]');


        if(locationDropdown){
            for (let i = 0; i < locationDropdown.options.length; i++) {
                const option = locationDropdown.options[i];

                if (option.value.includes(':')) {
                    const [location, email] = option.value.split(':');

                    option.textContent = location.trim();

                    option.setAttribute('data-email', email.trim());
                }
            }

            locationDropdown.addEventListener('change', function() {
                const selectedOption = locationDropdown.options[locationDropdown.selectedIndex];

                const email = selectedOption.getAttribute('data-email');
                if (email) {
                    hiddenEmailInput.value = email;
                }
            });
        }


        const buttons = document.querySelectorAll('.button--primary, .button--action');

        buttons.forEach(button => {
            const highlight = document.createElement('div');
            highlight.classList.add('highlight');
            button.appendChild(highlight);
        });

        let observer = new MutationObserver(mutationCallback);

        let config = {
            childList: true,
            subtree: true
        };

        observer.observe(document.body, config);

        document.querySelectorAll('.js-stickyBox').forEach(pinBox => {
            applyStickyBox(pinBox, {
                offsetTop: offsetTopStickyBox
            });
        });

        let gsapInitialized = false;

        function observeAutoScoutPreview() {
            const targetNode = document.getElementById('autoscoutPreview');

            if (targetNode) {
                const observer = new MutationObserver((mutationsList, observer) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            const hasProduct = targetNode.querySelector('.product');
                            if (hasProduct && !gsapInitialized) {
                                gsapInitialized = true;
                                setTimeout(() => {
                                    initializeGSAPAnimations();
                                }, 300);
                                observer.disconnect();
                            }
                        }
                    }
                });

                observer.observe(targetNode, { childList: true, subtree: true });
            } else {
                if (!gsapInitialized) {
                    gsapInitialized = true;
                    setTimeout(() => {
                        initializeGSAPAnimations();
                    }, 300);
                }
            }
        }
        observeAutoScoutPreview();
    });


    function initializeTooltips() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }

    initializeTooltips();
}

initialize();


function adjustMobileBottomBar() {
    const footer = document.querySelector('.footer');
    const mobileBottomBar = document.querySelector('.mobileBottomBar');
    const widgetButtonContainer = document.querySelector('.ap-widget-button-container');

    if (footer && (mobileBottomBar || widgetButtonContainer)) {
        function isFooterOnScreen() {
            const footerRect = footer.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            return (
                footerRect.top < windowHeight &&
                footerRect.bottom >= 0
            );
        }

        function updateMobileBottomBarPosition() {
            const mobileBottomBarTransform = isFooterOnScreen() ? `translateY(100%)` : 'translateY(0)';
            const widgetButtonTransform = isFooterOnScreen() ? `translateX(-150%)` : 'translateX(0)';

            if (mobileBottomBar) {
                mobileBottomBar.style.transform = mobileBottomBarTransform;
            }

            if (widgetButtonContainer) {
                widgetButtonContainer.style.transform = widgetButtonTransform;
            }
        }

        window.addEventListener('scroll', updateMobileBottomBarPosition);
        window.addEventListener('resize', updateMobileBottomBarPosition);

        updateMobileBottomBarPosition();
    }
}

document.addEventListener('DOMContentLoaded', adjustMobileBottomBar);
